.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
  display: flex;
  align-items: center;
  height: 50px !important;
  justify-content: space-between;

  .ant-select-selector {
    border-radius: 0.25rem !important;
    width: 10rem !important;
  }
}

[data-theme="dark"] .site-layout .site-layout-background {
  background: #141414;
}

// .ant-btn:active {
//   text-decoration: unset !important;
//   background: unset !important;
//   color: unset !important;
//   border-color: unset !important;
// }
  
#style-1::-webkit-scrollbar
{
    display:none;
}

.ant-table-cell {
  color: #000000 !important;
}