.report{
    &-btn {
          &-blockSearch{
            width: 100%;
            color: white;
            background-color: #3770eb;
            border-color: #696969;
            margin-bottom: 10px;
            padding: 0.25rem 0.5rem;
            font-size: 0.875rem;
            line-height: 1.5;
            border-radius: 0.2rem;
          }
          &-blocksms{
            width: 100%;
            color: #23282c;
            background-color: #ffa500;
            border-color: #ffa500;
            margin-bottom: 10px;
            padding: 0.25rem 0.5rem;
            font-size: 0.875rem;
            line-height: 1.5;
            border-radius: 0.2rem;
          }
          &-blockExport{
            width: 100%;
            color: white;
            background-color: #696969;
            border-color: #420420;
            margin-bottom: 10px;
            padding: 0.25rem 0.5rem;
            font-size: 0.875rem;
            line-height: 1.5;
            border-radius: 0.2rem;
          }
          &-blockClear{
            width: 100%;
            color: #23282c;
            background-color: white;
            border-color: #696969;
            margin-bottom: 10px;
            padding: 0.25rem 0.5rem;
            font-size: 0.875rem;
            line-height: 1.5;
            border-radius: 0.2rem;
          }
    }
    &-table-row {
        .ant-table-wrapper {
          width: -webkit-fill-available;
        }
    }
    &-table-row1 {
        .ant-table-wrapper {
          width: 50%!important;
          margin: auto!important;
        }
    }
}
.ant-col ant-col-2{
    margin-left: auto!important;
}
.ant-table-thead{
    align-items: center;
    justify-content: center;
}
/* td.ant-table-cell{
    text-align: center;
    border-color: black!important;
}
th.ant-table-cell{
    text-align: center!important;
    background-color:#B0E0E6!important;
    border-color: black!important; 
}
.ant-table {
    outline: ridge;
    outline-color: black;
}
.ant-radio-inner{
    border-color: black!important;
} */
.edit-report {
    color:#1890ff;;
    margin-right: 0.5rem;
}
.edit-report:hover {
    cursor: pointer;
}
th.day-report-table{
  font-weight: bold !important;
  font-size: 20px !important;
}