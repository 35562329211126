.custom-modal {
  &-grid {
    .ant-modal-content {
      padding: 1rem;

      .ant-modal-close {
        top: 1rem !important;
        right: 1rem !important;
        color: #ffffff !important;
      }

      .ant-modal-header {
        background-color: #a64d79;

        .ant-modal-title {
          color: #ffffff;
          font-size: larger;
          text-align: center;
          padding: 6px;;
        }
      }

      .ant-modal-body {
        padding: unset !important;
        border: 1px solid #c8ced3;
        margin-top: -8px;
      }
    }

    &-booking-info {
      padding: 1rem;

      .driver-attendance-appmanager {

        &-active,
        &-inactive {
          width: 100% !important;
          text-align: center;
          color: #ffffff;
          border-radius: 0.25rem;
        }

        &-active {
          background-color: green !important;
        }

        &-inactive {
          background-color: orange !important;
        }
      }
    }
  }

  &-form {
    .ant-modal-content {
      padding: 1rem;

      .ant-modal-close {
        top: 1rem !important;
        right: 1rem !important;
        color: #ffffff !important;
      }

      .ant-modal-header {
        background-color: #a64d79;

        .ant-modal-title {
          color: #ffffff;
          font-size: larger;
          text-align: center;
          padding: 6px;
        }
      }

      .ant-modal-body {
        border: 1px solid #c8ced3;
        margin-top: -8px;
        padding: 10px;

        .ant-input-number {
          width: 100% !important;
        }

        .ant-form-item {
          margin-bottom: 0.8rem;
        }
      }
    }

    &-submit-btn {
      background-color: #f6b26b;
      border-color: #f6b26b;
      margin-right: 16px;
      margin-top: 10px;
      margin-bottom: 10px;
      border-radius: 0.25rem;
    }

    &-submit-btn:hover {
      color: #23282c;
      background-color: #b3bbc2;
      border-color: #acb5bc;
    }
  }

  &-cancel-form {
    .ant-modal-content {
      padding: 1rem;

      .ant-modal-close {
        top: 1rem !important;
        right: 1rem !important;
        color: #ffffff !important;
      }

      .ant-modal-header {
        background-color: #a64d79;
        padding: 8px;

        .ant-modal-title {
          color: #ffffff;
          font-size: larger;
          text-align: center;
        }
      }

      .ant-modal-body {
        border: 1px solid #c8ced3;
        margin-top: -8px;
        padding: 20px;
        .ant-col{
          padding: 5px;
          font-weight: 500;
        }

        .ant-form-item {
          margin-bottom: 0.8rem;
        }
      }
    }

    &-submit-btn {
      background-color: #f6b26b;
      border-color: #f6b26b;
      margin-right: 16px;
      margin-top: 10px;
      margin-bottom: 10px;
      border-radius: 0.25rem;
    }

    &-submit-btn:hover {
      color: #23282c;
      background-color: #b3bbc2;
      border-color: #acb5bc;
    }
  }

  &-approve-attendance {
    .ant-modal-content {
      padding: 1rem;

      .ant-modal-close {
        top: 1rem !important;
        right: 1rem !important;
        color: #ffffff !important;
      }

      .ant-modal-header {
        background-color: #a64d79;

        .ant-modal-title {
          color: #ffffff;
          font-size: larger;
          text-align: center;
          padding: 6px;
        }
      }

      .ant-modal-body {
        border: 1px solid #c8ced3;
        margin-top: -8px;
        padding: 5px;
      }
    }

    &-approve-btn,
    &-cancel-btn {
      background-color: #f6b26b;

      margin-right: 16px;
      margin-top: 10px;
      margin-bottom: 10px;
      border-radius: 0.25rem;
    }

    &-approve-btn {
      background-color: #6aa84f;
      border-color: #6aa84f;
    }

    &-approve-btn:hover,
    &-cancel-btn:hover {
      color: #23282c;
      background-color: #b3bbc2;
      border-color: #acb5bc;
    }

    &-btn-left-column {
      text-align: right !important;
    }
  }
}

.block-driver-attendance {
  .ant-table-wrapper {
    width: 100% !important;
  }
}

// .new-booking-assign {
//   .ant-table-tbody {
//     .ant-table-row {
//       cursor: pointer !important;
//     }
//   }
// }
.confirmModal .ant-modal-body, .confirmModal .ant-modal-footer{
  border: none !important;
  text-align: center !important;
}
.confirmModal .ant-modal-footer{
  display: none;
  height: 0;
}

.custom-modal-grid-booking-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin: auto;
}

.custom-modal-grid-booking-grid .ant-spin-nested-loading {
  width: 100%;
}

.custom-modal-grid-booking-grid .ant-table  {
  margin: 0 auto; /* Center table horizontally */
}
.custom-modal-approve-attendance .ant-modal-content .ant-modal-body .ant-row {
  padding: 5px 0px;
}
.confirm-modal{
  width: 100%;
}
.confirm-modal .ConfirmButton{
  text-align: center;
}
.confirm-modal p{
  padding: 10px;
  text-align: center;
}
.confirm-modal button{
  width: 100px;
}
