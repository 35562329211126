.booking {
  &-btn {
    &-add {
      background-color: #6fa8dc;
      border-color: #6fa8dc;
      width: 100%;
      border: 1px solid transparent;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.25rem;
    }

    &-new {
      background-color: #f4cccc;
      border-color: #f4cccc;
      width: 100%;
      border: 1px solid transparent;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.25rem;
    }

    &-online {
      background-color: #b6d7a8;
      border-color: #b6d7a8;
      width: 100%;
      border: 1px solid transparent;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.25rem;
    }

    &-rac {
      background-color: #f1c232;
      border-color: #f1c232;
      width: 100%;
      border: 1px solid transparent;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.25rem;
    }

    &-live {
      background-color: #6aa84f;
      border-color: #6aa84f;
      width: 100%;
      border: 1px solid transparent;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.25rem;
    }

    &-completed {
      background-color: #d9d9d9;
      border-color: #d9d9d9;
      width: 100%;
      border: 1px solid transparent;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.25rem;
    }

    &-buttonTrue {
      background-color: rgb(120, 226, 245) !important;
      color: black !important;
    }
    
    &-buttonFalse {
    }
    
    &-enquiry {
      background-color: #b4a7d6;
      border-color: #b4a7d6;
      width: 100%;
      border: 1px solid transparent;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.25rem;
    }

    &-cancelled {
      background-color: #df786f;
      border-color: #df786f;
      width: 100%;
      border: 1px solid transparent;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.25rem;
    }

    &-telecancel {
      background-color: #ea4335;
      border-color: #ea4335;
      width: 100%;
      border: 1px solid transparent;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.25rem;
    }

    &-lmv {
      background-color: #b7b7b7;
      border-color: #b7b7b7;
      width: 100%;
      border: 1px solid transparent;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.25rem;
    }

    &-reassign {
      color: #23282c;
      background-color: #acb5bc;
      border-color: #a5aeb7;
      width: 100%;
      border: 1px solid transparent;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.25rem;
    }
    &-preassign {
      color: #23282c;
      background-color: #b6d7a8;
      border-color: #b6d7a8;
      width: 100%;
      border: 1px solid transparent;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.25rem;
    }

    &-complete {
      background-color: #93c47d;
      border-color: #93c47d;
      width: 100%;
      border: 1px solid transparent;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.25rem;
    }

    &-cancel {
      background-color: #e06666;
      border-color: #e06666;
      margin-right: 16px;
      width: 100%;
      border: 1px solid transparent;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.25rem;
    }

    &-assign {
      background-color: #34a853;
      border-color: #34a853;
      margin-right: 16px;
      width: 100%;
      border: 1px solid transparent;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.25rem;
    }

    &-rac {
      background-color: #ffd966;
      border-color: #ffd966;
      margin-right: 16px;
      width: 100%;
      border: 1px solid transparent;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.25rem;
    }

    &-enquiry {
      background-color: #b4a7d6;
      border-color: #b4a7d6;
      margin-right: 16px;
      width: 100%;
      border: 1px solid transparent;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.25rem;
    }
    &-blockExport{
      width: 100%;
      color: white;
      background-color: #696969;
      border-color: #420420;
      margin-bottom: 10px;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.2rem;
    }
    &-update{
      //width: 100%;
      color: #23282c;
      background-color: #a5e684;
      border-color: #a5e684;
      margin-bottom: 10px;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.2rem;
    }
    &-blockSearch{
      width: 100%;
      color: white;
      background-color: #3770eb;
      border-color: #420420;
      margin-bottom: 10px;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.2rem;
    }
    &-cancel:hover,
    &-cancel:active,
    &-complete:hover,
    &-complete:active,
    &-reassign:hover,
    &-reassign:active,
    &-assign:hover,
    &-assign:active,
    &-rac:hover,
    &-rac:active,
    &-enquiry:hover,
    &-enquiry:active,
    &-lmv:hover,
    &-lmv:active,
    &-telecancel:hover,
    &-telecancel:active,
    &-cancelled:hover,
    &-cancelled:active,
    &-enquiry:hover,
    &-enquiry:active,
    &-completed:hover,
    &-completed:active,
    &-live:hover,
    &-live:active,
    &-rac:hover,
    &-rac:active,
    &-online:hover,
    &-online:active,
    &-new:hover,
    &-new:active,
    &-add:hover,
    &-add:active {
      color: #23282c !important;
      background-color: #b3bbc2 !important;
      border-color: #acb5bc !important;
    }
  }

  &-child-routes {
    //margin-top: 0.5rem;

    &-button-section {
      //margin: 0.5rem 0.5rem;
    }
  }
}

.new-booking {
  .edit-booking {
    color: blue;
    margin-right: 0.5rem;
  }

  .edit-booking:hover {
    cursor: pointer;
  }
}

.ant-layout-content {
  //margin: 0 !important;
  //margin-left: 1rem !important;
}

.booking {
  .main-row {
    .ant-col {
      padding: 0.5rem;
    }

    //background-color: #FFFFFF !important;
  }
}

.booking-child-routes-button-section {
  background-color: #cddef7;
  padding: 1rem;
}

.selectedTab {
  background-color: #cddef7 !important;

}

.selectedTab:before,
.selectedTab:after {
  position: absolute;
  bottom: -1px;
  width: 6px;
  height: 6px;
  content: " ";
}

.selectedTab:before {
  left: -6px;
}

.selectedTab:after {
  right: -6px;
}

.selectedTab:after,
.selectedTab:before {
  border: 1rem solid #cddef7;
}

.selectedTab {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.selectedTab:before {
  border-bottom-right-radius: 0.5rem;
  border-width: 0 0.1rem 0.1rem 0;
}

.selectedTab:after {
  border-bottom-left-radius: 0.5rem;
  border-width: 0 0 0.1rem 0.1rem;
}
/* .ant-badge{
  width: 100%;
}
.ant-badge-count{
  color:black;
  font-size: 15px;
  border:none;
  box-shadow: none;
  font-weight: 900;
  background-color: transparent;
  z-index: 1;
} */
.booking-btn-online{
  padding: 4px 10px;
}