.container {
  background-color: #a64d79;
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}
.paper {
  background-color: white;
  margin-top: 75px;
  padding-top: 2rem;
  padding-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}
.form {
  width: 90%;
  height: 70%;
  margin-top: spacing(1);
}
.login-btn-primary {
  margin-top: 2rem;
  height: 2.5rem;
  width: 100%;
  border-radius: 0.25rem;
}
.login-copyright-section {
  text-align: center !important;
  margin-top: 2rem;
  font-size: 1rem !important;
  color: rgba(0, 0, 0, 0.54) !important;
}
.login-container {
  .ant-checkbox {
    color: #000000 !important;
  }
  .ant-checkbox + span {
    color: #000000 !important;
  }
}
