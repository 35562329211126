.driver-attendance {
  .ant-select-selection {
    background-color: green;
  }

  bottom {
    color: #696969;
  }

  &-total-count {
    margin-top: 0.25rem !important;
    font-weight: 600;
    font-family: "Poppins";
  }

  &-btn {

    &-approve {
      width: 100%;
      background-color: #34a853;
      border-color: #34a853;
      margin-bottom: 10px;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.5;
      border-radius: 0.2rem;
      color: #23282c;
      font-weight: 600;

    }

    &-logout {
      width: 100%;
      color: #23282c;
      background-color: #cc4125;
      border-color: #cc4125;
      margin-bottom: 10px;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.5;
      border-radius: 0.2rem;
      font-weight: 600;
    }

    &-leave {
      width: 100%;
      color: #23282c;
      background-color: #e06666;
      border-color: #e06666;
      margin-bottom: 10px;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.5;
      border-radius: 0.2rem;
      font-weight: 600;
    }

    &-block {
      width: 100%;
      color: #23282c;
      background-color: #ff9900;
      border-color: #ff9900;
      margin-bottom: 10px;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.5;
      border-radius: 0.2rem;
      font-weight: 600;
    }

    &-appmanager {
      width: auto;
      background-color: #76a0e3;
      border-color: #76a0e3;
      margin-bottom: 10px;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.5;
      border-radius: 0.2rem;
      font-weight: 600;
    }

    &-onduty {
      width: 100%;
      color: #23282c;
      background-color: #b7eb9c;
      border-color: #b7eb9c;
      margin-bottom: 10px;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.5;
      border-radius: 0.2rem;
      font-weight: 600;
    }

    &-loggedout {
      width: 100%;
      color: #23282c;
      background-color: #ffffff;
      border-color: #696969;
      margin-bottom: 10px;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.5;
      border-radius: 0.2rem;
      font-weight: 600;
    }

    &-onleave {
      width: 100%;
      color: #23282c;
      background-color: #c27ba0;
      border-color: #c27ba0;
      margin-bottom: 10px;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.5;
      border-radius: 0.2rem;
      font-weight: 600;
    }

    &-ontrip {
      width: 100%;
      color: #23282c;
      background-color: #ffdf80;
      border-color: #ffdf80;
      margin-bottom: 10px;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.5;
      border-radius: 0.2rem;
      font-weight: 600;
    }

    &-blocked {
      width: 100%;
      color: #23282c;
      background-color: #f39991;
      border-color: #f39991;
      margin-bottom: 10px;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.5;
      border-radius: 0.2rem;
      font-weight: 600;
    }

    &-all {
      width: 100%;
      color: white;
      background-color: #696969;
      border-color: #696969;
      margin-bottom: 10px;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.5;
      border-radius: 0.2rem;
      font-weight: 600;
    }

    &-approve:hover,
    &-logout:hover,
    &-leave:hover,
    &-block:hover,
    &-appmanager:hover {
      color: #23282c;
      background-color: #b3bbc2;
      border-color: #acb5bc;
    }

    &-diabled {
      width: 100%;
      color: #23282c;
      background-color: #b3bbc2;
      border-color: #acb5bc;
      margin-bottom: 10px;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.5;
      border-radius: 0.2rem;
    }
  }

  // &-table-row {
  //   // .ant-table-wrapper {
  //   //   width: -webkit-fill-available;
  //   // }
  // }
}

.ant-badge {
  width: 100%;
}

.ant-badge-count {
  color: black;
  font-size: 15px;
  border: none;
  box-shadow: none;
  font-weight: 900;
  background-color: transparent;
  z-index: 1;
}

.modal-button-width {
  width: auto;
}

/* .driver-attendance-btn-attmanager .ant-select-selector,
.driver-attendance-btn-attmanager .ant-select-selection-placeholder{
  background-color: #6fa8dc !important;
  border: none !important;
  color: #23282c !important;
} */
.driver-attendance-btn-attmanager {
  background-color: #6fa8dc !important;
  border: none !important;
  color: #23282c !important;
  font-weight: 600;
}

.ant-table-content {
  overflow-x: scroll;
  border-color: #acb5bc;

}

.ant-table table {
  width: auto;
}

.ant-btn-default:disabled {
  width: 100%;
  font-weight: 600;
}

.sticky {
  position: sticky;
  top: 0;
  padding-top: 10px;
  margin-top: -10px;
  z-index: 1000;
  background: rgb(255, 255, 255);
}