.credit {
  &-btn {
    &-generate {
      width: 100%;
      background-color: #34a853;
      border-color: #34a853;
      //margin-bottom: 10px;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.2rem;
    }
    &-blockSearch {
      width: 100%;
      color: white;
      background-color: #3770eb;
      border-color: #696969;
      //margin-bottom: 10px;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.2rem;
    }
    &-blockExport{
        width: 100%;
        color: white;
        background-color: #696969;
        border-color: #696969;
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem; 
      }
  }
  &-table-row {
    .ant-table-wrapper {
      width: -webkit-fill-available;
    }
  }
  &-blueText{
      color: #3770eb;
      font-size: 18px;
      font-family: "Times New Roman";
      font-weight: bold;
      margin-right: 10px;
  }
}
.credit .ant-card-head-title {
  flex: none;
}
.credit .ant-card-extra {
  margin-left: 10px;
}
