.add-booking {
  margin: 0.5rem 0.5rem 0.5rem 0;

  &-left-section-column {
    margin: 0.5rem 0.5rem 0.5rem 0;
    border: 1px solid #c8ced3;
    border-radius: 0.3rem;
    background-color: #82439a;

    &-header-row {
      padding: 0.5rem;
      place-content: center;
      border-top-right-radius: 0.3rem;
      border-top-left-radius: 0.3rem;
      background-color: #f0f3f5;
      border-bottom: 1px solid #c8ced3;

      h3 {
        font-size: 1.05rem;
        padding: 0rem 0.5rem 0 0.5rem;
      }
    }

    &-form {
      padding: 1rem 0.5rem;

      &-col {
        padding: 0 0.5rem;

        .ant-picker {
          width: 100% !important;
        }

        .ant-select {
          color: #000000 !important;
        }
      }

      &-btn-row {
        margin-left: 0.5rem;

        &-booknow {
          color: #fff;
          background-color: #20a8d8;
          border-color: #20a8d8;
          font-size: 0.76563rem;
          line-height: 1.5;
          border-radius: 0.2rem;
        }

        &-rac {
          color: #fff;
          background-color: #f86c6b;
          border-color: #f86c6b;
          font-size: 0.76563rem;
          line-height: 1.5;
          border-radius: 0.2rem;
        }

        &-enquiry {
          color: #23282c;
          background-color: #ffc107;
          border-color: #ffc107;
          font-size: 0.76563rem;
          line-height: 1.5;
          border-radius: 0.2rem;
        }
      }
    }
  }

  &-right-section-column {

    &-bookinghistorycolumn{
      display: block;
    flex: 1 0 24%;
    max-width: 25%;
    position: relative;
 
    min-height: 1px;
    }
    //padding: 0.5rem;
    &-each-block {
      margin: 0.5rem;
      border: 1px solid #c8ced3;
      border-radius: 0.3rem;

      .ant-table-wrapper {
        width: -webkit-fill-available;
      }
    }

    &-header-row {
      padding: 0.5rem;
      place-content: center;
      border-top-right-radius: 0.3rem;
      border-top-left-radius: 0.3rem;
      background-color: #f0f3f5;
      border-bottom: 1px solid #c8ced3;

      h3 {
        font-size: 1.05rem;
        place-content: center;
        padding: 0rem 0.5rem 0 0.5rem;
      }
    }

    &-fare-info {
      padding-top: 0.25rem;
      background-color: #ffffff;

      &-each-row {
        padding: 0.25rem 1rem;
      }

      &-fare-summary-row {
        background-color: #82439a;
        padding-left: 1rem;
        padding-top:0.5rem ;
        padding-bottom: 0.5rem;
        font-style: oblique;
        font-size: large;
        color: whitesmoke;
        // padding: 0.5rem 0.5rem 0.5rem 1.5rem;
        border-bottom-right-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
      }
    }
  }
}

// .add-booking-left-section-column-form-col {

//   .ant-form-item-has-error .ant-form-item-explain,
//   .ant-form-item-has-error .ant-form-item-split {
//     color: #00ff2a
//   }
// }
// .add-booking-left-section-column-form-col {
//   .ant-row.ant-form-item {
//     margin-bottom: 0;
//   }
// }

.form-error-validation {
  color: #00ff2a;
  margin-bottom: 0;
}
.approxkm-value{
  color:red;
}