td.ant-table-cell {
  text-align: center;
  border-color: darkgray !important;
}
th.ant-table-cell {
  text-align: center !important;
  background-color: #b0e0e6 !important;
  border-color: darkgray !important;
}

.ant-table {
  outline: ridge;
  outline-color: lightgrey !important; 

}
.ant-radio-inner {
  border-color: black !important;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}
tr:hover {
  background-color: #ddd;
}
.ant-table-pagination.ant-pagination {
  margin: 5px 0;
}
